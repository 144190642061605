<template>
<div class="container-fluid">

    <!-- <div class="row"> -->
        <!-- <div class="col-sm-12"> -->
            <!-- <div class="page-title-box"> -->
                <!-- <div class="row align-items-center"> -->
                    <!-- <div class="col-md-8"> -->
                        <!-- <h4 class="page-title m-0">Profil</h4> -->
                    <!-- </div> -->
                    <!-- end col -->
                <!-- </div> -->
                <!-- end row -->
            <!-- </div> -->
            <!-- end page-title-box -->
        <!-- </div> -->
    <!-- </div> -->
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12">
            <div class="card m-b-30">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-8">
                            <h4 class="mt-0 header-title">Profil pengguna</h4>
                        </div>

                        <div class="col-4">
                        </div>
                    </div>

                    <!-- Content Here -->

                    <div class="section-body">
                        <h2 class="section-title">Hi, {{detail != null ? detail.nama_lengkap : 'User'}}! </h2>
                        <p class="section-lead">
                            Anda bisa merubah data pribadi di halaman ini.
                        </p>

                        <div class="row mt-sm-4">
                            <div class="col-12 col-md-12 col-lg-5">
                                <div class="card profile-widget">
                                    <div class="profile-widget-header">
                                        <img alt="image" id='edit-category-image' :src=" detail.foto != null ? url+`images/users/${detail.username}/${detail.foto}` : url+`/images/users/no_image.png`" class="rounded-circle profile-widget-picture">
                                        <div class="profile-widget-items">

                                            <div class="profile-widget-item">
                                                <div class="col-12">
                                                    <input type="file" name="file" id="" class='form-control-file' accept='image/*' @change="onChangePhotoEdit($event)"></div>
                                                <input type="hidden" name="MAX_FILE_SIZE" value="10485760">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile-widget-description">
                                        <div class="profile-widget-name">{{detail.nama_lengkap}}
                                            <div class="text-muted d-inline font-weight-normal">
                                                <div class="slash"></div> {{ detail.level }}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-7">
                                <div class="card">
                                    <form method="post" class="needs-validation" novalidate="" enctype="multipart/form-data" @submit.prevent="formValidation()">
                                        <div class="card-header">
                                            <h4>Edit Profil</h4>
                                        </div>
                                        <div class="card-body">

                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="col-form-label">Nama Lengkap</label>
                                                    <input type="text" :class="{'form-control':validation.nama_lengkap == null, 'form-control is-invalid':validation.nama_lengkap != null }" value="" required="" v-model="detail.nama_lengkap">
                                                    <div class="invalid-feedback">

                                                        {{ validation.nama_lengkap != null ? validation.nama_lengkap[0] :" " }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="col-form-label">Jenis Kelamin</label>
                                                    <select name="jenis_kelamin" id="" v-model="detail.jenis_kelamin" :class="{'form-control':validation.jenis_kelamin == null, 'form-control is-invalid':validation.jenis_kelamin != null }" required>
                                                        <option value="">Pilih salah satu..</option>
                                                        <option value="Laki-laki">Laki-laki</option>
                                                        <option value="Perempuan">Perempuan</option>
                                                    </select>

                                                    <div class="invalid-feedback">
                                                        {{ validation.jenis_kelamin != null ? validation.jenis_kelamin[0] : '' }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-7 col-12">
                                                    <label class="col-form-label">Email</label>
                                                    <input type="email" :class="{'form-control':validation.email == null, 'form-control is-invalid':validation.email != null }" value="" required="" v-model="detail.email">
                                                    <div class="invalid-feedback">
                                                        {{ validation.email != null ?  validation.email[0] : "" }}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-5 col-12">
                                                    <label class="col-form-label">Nomor HP</label>
                                                    <input type="text" :class="{'form-control':validation.hp == null, 'form-control is-invalid':validation.hp != null }" value="" required v-model="detail.hp">
                                                    <div class="invalid-feedback">
                                                        {{ validation.hp != null ?  validation.hp[0] : "" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="col-form-label">Username</label>
                                                    <input type="text" :class="{'form-control':validation.username == null, 'form-control is-invalid':validation.username != null }" value="" required="" v-model="detail.username">
                                                    <div class="invalid-feedback">
                                                        {{ validation.username != null ?  validation.username[0] : "" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-6 col-12">
                                                    <label class="col-form-label">Password</label> <small class="form-text text-muted">Kosongkan jika tidak ingin diganti</small>
                                                    <input type="password" id="password" :class="{'form-control':this.passError==false, 'form-control is-invalid':this.passError==true }" v-model="edit.password">
                                                    <div class="invalid-feedback">
                                                        {{ this.passError ?  'Password tidak sama!' : "" }}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6 col-12">
                                                    <label class="col-form-label">Konfirmasi Password</label> <small class="form-text text-muted">Kosongkan jika tidak ingin diganti</small>
                                                    <input type="password" data-parsley-equalto="#password" :class=" {'form-control':this.passError==false, 'form-control is-invalid':this.passError==true }" v-model="edit.c_password">
                                                    <div class="invalid-feedback">
                                                        {{ this.passError ?  'Password tidak sama!' : "" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="col-form-label">Tanggal Lahir</label>
                                                    <input name="description" type="date" :class="{'form-control':validation.tgl_lahir == null, 'form-control is-invalid':validation.tgl_lahir != null }" required v-model="detail.tgl_lahir">
                                                    <div class="invalid-feedback">
                                                        {{ validation.tgl_lahir != null ?  validation.tgl_lahir[0] : "" }}
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="col-form-label">Alamat</label>
                                                    <textarea :class="{'form-control summernote-simple':validation.alamat == null, 'form-control is-invalid summernote-simple':validation.alamat != null }" required v-model="detail.alamat"></textarea>
                                                    <div class="invalid-feedback">
                                                        {{ validation.alamat != null ?  validation.alamat[0] : "" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group mb-0 col-12">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button class="btn btn-primary">Simpan</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- End of Content -->
                </div>
            </div>
        </div>

    </div>

    <!-- end row -->

</div><!-- container fluid -->
</template>

<script>
import Swal from 'sweetalert2';
import iziToast from 'izitoast';
import axios from "axios";
import Loader from './../../components/Loader';
export default {
    created() {
        document.title = "Profil Pengguna - Rudaya ~ Connect The Art";
    },
    mounted() {
        this.userDetail(JSON.parse(localStorage.getItem('dataUser') || "[]").kode_user);
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('dataUser') || "[]"),
            fullPage: false,
            loader: '',
            users: [],
            validation: [],
            foto: '',
            detail: {},
            passError: false,
            edit: {
                kode_user: '',
                nama_lengkap: '',
                jenis_kelamin: '',
                level: '',
                email: '',
                username: '',
                password: '',
                c_password: '',
                hp: '',
                alamat: '',
                telegram_id: '',
            },
        }
    },

    methods: {

        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        formatTanggal(date) {
            moment(date).format(
                "DD MMMM YYYY"
            )
        },
        onChangePhotoEdit(event) {
            this.detail.foto = event.target.files[0];
            console.log(event.target.files[0]);
            $('#edit-category-image').attr('src', URL.createObjectURL(this.detail.foto));
        },

        userDetail(kode_user) {
            axios.get(this.url+`api/v1/auth/user`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },

                }, )
                .then(res => {
                    this.detail = res.data.user;
                    console.log(res.data.data);
                    // $('#modalDetail').modal('show');
                });
        },
        formValidation() {
            console.log(this.detail.foto);
            this.Loader();
            if (this.edit.password != this.edit.c_password) {
                this.passError = true;
                this.loader.hide();
            } else {
                this.passError = false;
                this.loader.hide();
                this.userUpdate();
            }
        },
        userUpdate() {
            let formData = new FormData();
            formData.append('kode_user', this.detail.kode_user);
            formData.append('nama_lengkap', this.detail.nama_lengkap);
            formData.append('jenis_kelamin', this.detail.jenis_kelamin);
            formData.append('email', this.detail.email);
            formData.append('username', this.detail.username);

            if (this.edit.password != '') {
                formData.append('password', this.edit.password);
                // formData.append('c_password', this.edit.c_password);
            }
            formData.append('hp', this.detail.hp);
            formData.append('foto', this.detail.foto);
            formData.append('alamat', this.detail.alamat);
            let uri = this.url+`api/v1/profile/update`;
            this.Loader();
            // this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');
            this.axios.post(uri, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.loader.hide();
                    this.passError = false;
                    // $("#formEdit").trigger("reset");
                    $('#modalEdit').modal('toggle');

                    iziToast.success({
                        title: 'Berhasil',
                        message: 'Edit profil berhasil!',
                    });
                    this.userDetail(this.detail.kode_user);
                }).catch(error => {
                    iziToast.warning({
                        title: 'Gagal!',
                        message: 'Edit profil gagal!',
                    })
                    this.validation = null;
                    this.passError = false;
                    this.loader.hide();

                    if (error.response.data.success == false) {
                        console.log("Validation messages", error.response.data.data);
                        this.validation = error.response.data.data;
                    }

                });
        },

    }
}
</script>

<style>
.image-table {
    width: 80px;
}

.image-table-users {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
}

.profile-widget .profile-widget-picture {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
    float: left;
    width: 120px;
    margin: -25px -5px 0 30px;
    position: relative;
    z-index: 1;
    height: 120px;
}
</style>
